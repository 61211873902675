import { useState, useEffect } from 'react';
import PersonalDataForm from '../../component/Panel/Forms/PersonalDataForm'; // New component for personal data form
import AddressDataForm from '../../component/Panel/Forms/AddressDataForm'; // New component for address data form
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DocumentDataForm from '../../component/Panel/Forms/DocumentDataForm';
import JurusanDataForm from '../../component/Panel/Forms/JurusanDataForm';
import SekolahDataForm from '../../component/Panel/Forms/SekolahDataForm';
import SelesaiPage from '../../component/Panel/Forms/SelesaiPage';
import { isAuth } from "../../helper/auth";
import axios from 'axios';
const Biodata = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [status, setStatus] = useState('')
    const user = isAuth();

    const token = JSON.parse(localStorage.getItem("token"));
    const header = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }

    useEffect(() => {
        getStatus();
        // eslint-disable-next-line
    }, []);

    const getStatus = async () => {
        await axios.get(`/api/formulir/status`, header)
            .then(response => {
                setStatus(response.data)
            })
            .catch(error => {
                console.error(`Failed to get $`, error);
            })
    }
    const tabsData = [
        {
            label: 'Data Jurusan',
            content: <JurusanDataForm status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />,
        },
        {
            label: 'Data Pribadi',
            content: <PersonalDataForm status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />,
        },
        {
            label: 'Data Alamat',
            content: <AddressDataForm status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />,
        },
        {
            label: 'Data Sekolah',
            content: <SekolahDataForm status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />,
        },

        {
            label: 'Upload Dokumen',
            content: <DocumentDataForm status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />
        },
        {
            label: 'Selesai',
            content: <SelesaiPage status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />
        }
    ];
    const goToNextTab = async () => {
        if (activeTab < tabsData.length - 1) {
            setActiveTab(activeTab + 1);
        }
    };
    useEffect(() => {
        getStatus();
        // eslint-disable-next-line
    }, [activeTab]);
    return (
        <>
            <div className="w-full mt-24 rounded-md h-screen" style={{ filter: "drop-shadow(0px 0px 9px rgba(0,0,0,0.18))" }}>
                <div className="p-4 bg-white">
                    <h1 className="text-2xl font-bold mb-4">Formulir PMB</h1>
                    <div className="p-4">
                        <div className="flex tabsData justify-center md:px-6 px-2 flex-wrap mb-4">
                            {tabsData.map((tab, index) => {
                                if (status[0]?.NA === 'Y') {
                                    return (activeTab <= index) ? (
                                        <button key={index} className={`mb-2 md:mb-0 border-x-[0.5px] border-gray-300 py-2 px-4 cursor-default  ${activeTab === index ? 'bg-[#60282a] font-bold text-white' : ' font-light text-gray-400 bg-gray-200'}`} >
                                            {tab.label}
                                        </button>
                                    ) : (
                                        <button key={index} className={`mb-2 md:mb-0 border-x-[0.5px] border-gray-300 py-2 px-4  ${activeTab === index ? 'bg-[#60282a] font-bold text-white' : ' font-light hover:bg-gray-300 bg-gray-200'}`} onClick={() => setActiveTab(index)}>
                                            {tab.label}
                                        </button>
                                    )
                                } else {
                                    return (
                                        <button key={index} className={`mb-2 md:mb-0 border-x-[0.5px] border-gray-300 py-2 px-4  ${activeTab === index ? 'bg-[#60282a] font-bold text-white' : ' font-light hover:bg-gray-300 bg-gray-200'}`} onClick={() => setActiveTab(index)}>
                                            {tab.label}
                                        </button>
                                    )
                                }
                            })}
                        </div>
                        <div className='w-full'>{tabsData[activeTab].content}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Biodata;
