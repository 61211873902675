import { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCamera, FaEye, FaTrashAlt } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import CSS for React Lightbox
import swal from 'sweetalert';
import pdf from '../../../assets/img/pdf.png'
const DocumentDataForm = ({ AplikanID, onSubmitSuccess, status }) => {
    let baseURL
    if (process.env.NODE_ENV === "development") {
        baseURL = 'http://localhost:3001'
    } else {
        baseURL = 'https://pmb.unespadang.ac.id'
    }
    const token = JSON.parse(localStorage.getItem("token"));
    const header = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
    }
    const readOnly = status === "N";
    const disable = status === "N";
    const [Ijazah, setIjazah] = useState(null); // Added state for file preview
    const [Ktp, setKtp] = useState(null); // Added state for second file preview
    const [Sertifikat, setSertifikat] = useState(null); // Added state for third file preview
    const [KartuKeluarga, setKartuKeluarga] = useState(null); // Added state for Kartu Keluarga file preview

    const [filePreviewIjazah, setFilePreviewIjazah] = useState(null); // Added state for file preview
    const [filePreviewKtp, setFilePreviewKtp] = useState(null); // Added state for second file preview
    const [filePreviewSertifikat, setFilePreviewSertifikat] = useState(null); // Added state for third file preview
    const [filePreviewKartuKeluarga, setFilePreviewKartuKeluarga] = useState(null); // Added state for Kartu Keluarga file preview

    const [ProfilePhoto, setProfilePhoto] = useState(null);
    const [filePreviewProfilePhoto, setFilePreviewProfilePhoto] = useState(null);

    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState('');

    useEffect(() => {
        getDocument();
        // eslint-disable-next-line
    }, []);

    const handleFileChange = (file, documentType) => {
        if (file) {
            const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
            if (!validTypes.includes(file.type)) {
                alert('Invalid file type. Only JPG, JPEG, PNG, and PDF are allowed.');
                return;
            }

            if (file.size > 1048576) {
                alert('File size must not exceed 1MB.');
                return;
            }

            const formData = new FormData();
            formData.append('file', file);
            formData.append('data', JSON.stringify({ AplikanID: AplikanID, document: documentType }));
            axios.post(`${baseURL}/api/files/upload`, formData, header)
                .then((response) => {

                    getDocument();
                    // switch (documentType) {
                    //     case 'ijazah':
                    //         setFilePreviewIjazah(file.type === 'application/pdf' ? 'pdf_logo.png' : URL.createObjectURL(file));
                    //         break;
                    //     case 'ktp':
                    //         setFilePreviewKtp(file.type === 'application/pdf' ? 'pdf_logo.png' : URL.createObjectURL(file));
                    //         break;
                    //     case 'sertifikat':
                    //         setFilePreviewSertifikat(file.type === 'application/pdf' ? 'pdf_logo.png' : URL.createObjectURL(file));
                    //         break;
                    //     case 'kartuKeluarga':
                    //         setFilePreviewKartuKeluarga(file.type === 'application/pdf' ? 'pdf_logo.png' : URL.createObjectURL(file));
                    //         break;
                    //     case 'profilePhoto':
                    //         setFilePreviewProfilePhoto(file.type === 'application/pdf' ? 'pdf_logo.png' : URL.createObjectURL(file));
                    //         break;
                    //     default:
                    //         break;
                    // }
                })
                .catch((error) => {
                    // Handle error if file upload fails
                });
        }
    };

    const handleButtonClick = (inputId) => {
        document.getElementById(inputId).click();
    };

    const getDocument = async () => {
        const documentTypes = ['ijazah', 'ktp', 'sertifikat', 'kartuKeluarga', 'profilePhoto'];
        const promises = await documentTypes.map(type =>
            axios.get(`${baseURL}/api/files/${AplikanID}/${type}`, header)
                .then(response => {
                    if (response.data[0]) {
                        const fileUrl = `${baseURL}/images/${response.data[0]?.filename}`;
                        switch (type) {
                            case 'ijazah':
                                setFilePreviewIjazah(response.data[0]?.extension === 'pdf' ? pdf : fileUrl);
                                setIjazah(response.data[0]);
                                break;
                            case 'ktp':
                                setFilePreviewKtp(response.data[0]?.extension === 'pdf' ? pdf : fileUrl);
                                setKtp(response.data[0]);
                                break;
                            case 'sertifikat':
                                setFilePreviewSertifikat(response.data[0]?.extension === 'pdf' ? pdf : fileUrl);
                                setSertifikat(response.data[0]);
                                break;
                            case 'kartuKeluarga':
                                setFilePreviewKartuKeluarga(response.data[0]?.extension === 'pdf' ? pdf : fileUrl);
                                setKartuKeluarga(response.data[0]);
                                break;
                            case 'profilePhoto':
                                setFilePreviewProfilePhoto(response.data[0]?.extension === 'pdf' ? pdf : fileUrl);
                                setProfilePhoto(response.data[0]);
                                break;
                            default:
                                break;
                        }
                    }
                })
                .catch(error => {
                    console.error(`Failed to get ${type}`, error);
                })
        );

        await Promise.all(promises);
    }

    const handleDeleteFile = (documentType, id) => {

        axios.delete(`${baseURL}/api/files/${id}`, header)
            .then((response) => {
                // Set preview state to null after successful deletion
                if (documentType === 'ijazah') {
                    setFilePreviewIjazah(null);
                } else if (documentType === 'ktp') {
                    setFilePreviewKtp(null);
                } else if (documentType === 'sertifikat') {
                    setFilePreviewSertifikat(null);
                } else if (documentType === 'kartuKeluarga') {
                    setFilePreviewKartuKeluarga(null);
                } else if (documentType === 'profilePhoto') {
                    setFilePreviewProfilePhoto(null);
                }
                alert('File deleted successfully.');
            })
            .catch((error) => {
                alert('Gagal Hapus file.');
            });
    };

    const finish = () => {
        // Check if all file previews are set
        if (!filePreviewIjazah || !filePreviewKtp || !filePreviewSertifikat || !filePreviewKartuKeluarga || !filePreviewProfilePhoto) {
            swal({
                title: "Error",
                text: "Semua dokumen harus diunggah sebelum menyimpan.",
                icon: "error",
                button: "OK",
            });
            return; // Stop the function if not all files are uploaded
        }

        swal({
            title: "Yakin Simpan?",
            text: "Data yang telah disimpan tidak dapat dirubah lagi",
            icon: "warning",
            buttons: {
                cancel: "Batal",
                text: "Lanjutkan"
            },
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const form = { NA: "N" }
                    axios.patch(`api/formulir/finish`, form, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                        .then((res) => {
                            onSubmitSuccess()
                            swal("Berhasil Disimpan", {
                                icon: "success",
                            });


                        }).catch((e) => {
                            console.log(e);
                        })
                } else {
                    // swal("Your imaginary file is safe!");
                }
            });
    }

    return (
        <div className="md:mt-4 mb-2 w-full p-0 md:p-4 ">
            <div className="mb-4 w-full justify-center flex items-center  px-2">
                <div className=' md:w-3/4 md:grid md:grid-cols-3 md:gap-3 w-full'>
                    <div className='flex justify-center w-full py-2 items-center'>
                        <div className='borde border-gray-200 border-2 m-2 bg-gray-100 w-56 h-56 flex justify-center rounded-lg items-center '>
                            <input type="file" id="fileInputIjazah" style={{ display: 'none' }} onChange={(e) => handleFileChange(e.target.files[0], 'ijazah')} readOnly={readOnly} disabled={disable} />
                            {filePreviewIjazah ? (
                                <div className=' relative   flex w-full h-full justify-center' >
                                    <div className="absolute z-50 h-full w-full bg-transparent hover:bg-black/20 flex items-center justify-center group-hover:bottom-0  transition-all ease-in duration-300">
                                        <div className=' w-full flex justify-center text-center'>
                                            <div className=" w-[80%] ">
                                                <button type="button" className=" w-full my-2  bg-[#60282adb] justify-center rounded-md flex items-center text-white py-2 px-4" onClick={() => { if (!lightboxIsOpen) { setLightboxIsOpen(true); setLightboxImage(filePreviewIjazah); } }}><FaEye /> <span className=" px-2">Lihat Ijazah</span></button>
                                                {!readOnly && !disable && (
                                                    <button type="button" className=" w-full my-2 bg-[#60282adb] justify-center rounded-md flex items-center text-white py-2 px-5" onClick={() => handleDeleteFile('ijazah', Ijazah?.id)}><FaTrashAlt /> <span className=" px-2">Hapus</span></button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <img className=' rounded-lg object-cover' src={filePreviewIjazah} alt="File Preview 1" />
                                </div>
                            ) : (
                                <button onClick={() => handleButtonClick('fileInputIjazah')} className='  p-1 text-gray-400 flex w-full justify-center'>
                                    <div className=' w-full'>
                                        <div className='text-6xl flex justify-center w-full '><FaCamera /></div>
                                        <div>Upload Ijazah</div>
                                    </div>
                                </button>
                            )}
                        </div>
                    </div>

                    <div className='flex justify-center w-full py-2 items-center'>
                        <div className='borde border-gray-200 border-2 m-2 bg-gray-100 w-56 h-56 flex justify-center rounded-lg items-center '>
                            <input type="file" id="fileInputKtp" style={{ display: 'none' }} onChange={(e) => handleFileChange(e.target.files[0], 'ktp')} readOnly={readOnly} disabled={disable} />

                            {filePreviewKtp ? (
                                <div className=' relative   flex w-full h-full justify-center' >
                                    <div className="absolute z-50 h-full w-full bg-transparent hover:bg-black/20 flex items-center justify-center group-hover:bottom-0  transition-all ease-in duration-300">
                                        <div className=' w-full flex justify-center text-center'>
                                            <div className=" w-[80%] ">

                                                <button type="button" className=" w-full my-2  bg-[#60282adb] justify-center rounded-md flex items-center text-white py-2 px-4" onClick={() => { if (!lightboxIsOpen) { setLightboxIsOpen(true); setLightboxImage(filePreviewKtp); } }}><FaEye /> <span className=" px-2">Lihat KTP</span></button>
                                                {!readOnly && !disable && (
                                                    <button type="button" className=" w-full my-2 bg-[#60282adb] justify-center rounded-md flex items-center text-white py-2 px-5" onClick={() => handleDeleteFile('ktp', Ktp?.id)}><FaTrashAlt /> <span className=" px-2">Hapus</span></button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <img className=' rounded-lg object-cover' src={filePreviewKtp} alt="File Preview 2" />
                                </div>
                            ) : (
                                <button onClick={() => handleButtonClick('fileInputKtp')} className='  p-1 text-gray-400 flex w-full justify-center'>
                                    <div className=' w-full'>
                                        <div className='text-6xl flex justify-center w-full '><FaCamera /></div>
                                        <div>Upload KTP</div>
                                    </div>
                                </button>
                            )}
                        </div>
                    </div>
                    <div className='flex justify-center w-full py-2 items-center'>
                        <div className='borde border-gray-200 border-2 m-2 bg-gray-100 w-56 h-56 flex justify-center rounded-lg items-center '>
                            <input type="file" id="fileInputSertifikat" style={{ display: 'none' }} onChange={(e) => handleFileChange(e.target.files[0], 'sertifikat')} readOnly={readOnly} disabled={disable} />
                            {filePreviewSertifikat ? (
                                <div className=' relative  flex w-full h-full justify-center' >
                                    <div className="absolute z-50 h-full w-full bg-transparent hover:bg-black/20 flex items-center justify-center group-hover:bottom-0  transition-all ease-in duration-300">
                                        <div className=' w-full flex justify-center text-center'>
                                            <div className=" w-[80%] ">

                                                <button type="button" className=" w-full my-2  bg-[#60282adb] justify-center rounded-md flex items-center text-white py-2 px-4" onClick={() => { if (!lightboxIsOpen) { setLightboxIsOpen(true); setLightboxImage(filePreviewSertifikat); } }}><FaEye /> <span className=" px-2">Lihat Sertifikat</span></button>
                                                {!readOnly && !disable && (
                                                    <button type="button" className=" w-full my-2 bg-[#60282adb] justify-center rounded-md flex items-center text-white py-2 px-5" onClick={() => handleDeleteFile('sertifikat', Sertifikat?.id)}><FaTrashAlt /> <span className=" px-2">Hapus</span></button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <img className=' rounded-lg object-cover' src={filePreviewSertifikat} alt="File Preview 3" />
                                </div>
                            ) : (
                                <button onClick={() => handleButtonClick('fileInputSertifikat')} className='  p-1 text-gray-400 flex w-full justify-center'>
                                    <div className=' w-full'>
                                        <div className='text-6xl flex justify-center w-full '><FaCamera /></div>
                                        <div>Upload Sertifikat</div>
                                    </div>
                                </button>
                            )}
                        </div>
                    </div>

                    <div className='flex justify-center w-full py-2 items-center'>
                        <div className='borde border-gray-200 border-2 m-2 bg-gray-100 w-56 h-56 flex justify-center rounded-lg items-center '>
                            <input type="file" id="fileInputKartuKeluarga" style={{ display: 'none' }} onChange={(e) => handleFileChange(e.target.files[0], 'kartuKeluarga')} readOnly={readOnly} disabled={disable} />

                            {filePreviewKartuKeluarga ? (
                                <div className=' relative   flex w-full h-full justify-center' >
                                    <div className="absolute z-50 h-full w-full bg-transparent hover:bg-black/20 flex items-center justify-center group-hover:bottom-0  transition-all ease-in duration-300">
                                        <div className=' w-full flex justify-center text-center'>
                                            <div className=" w-[80%] ">
                                                <button type="button" className=" w-full my-2  bg-[#60282adb] justify-center rounded-md flex items-center text-white py-2 px-4" onClick={() => { if (!lightboxIsOpen) { setLightboxIsOpen(true); setLightboxImage(filePreviewKartuKeluarga); } }}><FaEye /> <span className=" px-2">Lihat Kartu Keluarga</span></button>
                                                {!readOnly && !disable && (
                                                    <button type="button" className=" w-full my-2 bg-[#60282adb] justify-center rounded-md flex items-center text-white py-2 px-5" onClick={() => handleDeleteFile('kartuKeluarga', KartuKeluarga?.id)}><FaTrashAlt /> <span className=" px-2">Hapus</span></button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <img className=' rounded-lg object-cover' src={filePreviewKartuKeluarga} alt="File Preview Kartu Keluarga" />
                                </div>
                            ) : (
                                <button onClick={() => handleButtonClick('fileInputKartuKeluarga')} className='  p-1 text-gray-400 flex w-full justify-center'>
                                    <div className=' w-full'>
                                        <div className='text-6xl flex justify-center w-full '><FaCamera /></div>
                                        <div>Upload Kartu Keluarga</div>
                                    </div>
                                </button>
                            )}
                        </div>
                    </div>

                    <div className='flex justify-center w-full py-2 items-center'>
                        <div className=' border-gray-200 border-2 m-2 bg-gray-100 w-56 h-56 flex justify-center rounded-lg items-center '>
                            <input type="file" id="fileInputProfilePhoto" style={{ display: 'none' }} onChange={(e) => handleFileChange(e.target.files[0], 'profilePhoto')} readOnly={readOnly} disabled={disable} />
                            {filePreviewProfilePhoto ? (
                                <div className='relative flex w-full h-full justify-center' >
                                    <div className="absolute z-50 h-full w-full bg-transparent hover:bg-black/20 flex items-center justify-center group-hover:bottom-0 transition-all ease-in duration-300">
                                        <div className='w-full flex justify-center text-center'>
                                            <div className="w-[80%]">
                                                <button type="button" className="w-full my-2 bg-[#60282adb] justify-center rounded-md flex items-center text-white py-2 px-4" onClick={() => { if (!lightboxIsOpen) { setLightboxIsOpen(true); setLightboxImage(filePreviewProfilePhoto); } }}><FaEye /> <span className="px-2">Lihat Foto Profil</span></button>
                                                {!readOnly && !disable && (
                                                    <button type="button" className="w-full my-2 bg-[#60282adb] justify-center rounded-md flex items-center text-white py-2 px-5" onClick={() => handleDeleteFile('profilePhoto', ProfilePhoto?.id)}><FaTrashAlt /> <span className="px-2">Hapus</span></button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <img className='rounded-lg object-cover' src={filePreviewProfilePhoto} alt="ProfilePhotoPreview" />
                                </div>
                            ) : (
                                <button onClick={() => handleButtonClick('fileInputProfilePhoto')} className='p-1 text-gray-400 flex w-full justify-center'>
                                    <div className='w-full'>
                                        <div className='text-6xl flex justify-center w-full '><FaCamera /></div>
                                        <div>Upload Foto Profil</div>
                                    </div>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

            </div>
            <button onClick={finish} className='bg-[#60282a] hover:bg-red-900 text-white text-2xl font-bold py-2 px-4 rounded ' type='button'>Selesai</button>
            {lightboxIsOpen && (
                <Lightbox
                    mainSrc={lightboxImage}
                    onCloseRequest={() => setLightboxIsOpen(false)}
                />
            )}
        </div>
    );
};

export default DocumentDataForm;