import mhs from "../assets/img/mhs.svg";
import briva from "../assets/img/briva.png"
import { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Header from "../component/Home/Header";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FaCheckCircle, FaCopy, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { Backdrop, CircularProgress } from "@mui/material";
import swal from 'sweetalert'
import LoadingSmall from "../component/LoadingSmall";
function Daftar() {
    // const { snapEmbeded } = MidtransSnap()
    const [formInput, setFormInput] = useState({
        nama: '',
        email: '',
        jenjang: '',
        handphone: '' // Initialize handphone to an empty string
    })
    const [response, setResponse] = useState()
    const [phoneStatus, setPhoneStatus] = useState(); // Add this state for phone error
    const [typingTimeout, setTypingTimeout] = useState(null); // State to hold the timeout ID
    const [load, setLoad] = useState(false);

    const formInputHeandler = async (e) => {
        const { name, value } = e.target;
        setFormInput(prev => ({ ...prev, [name]: value }));

        // Clear previous timeout if it exists
        clearTimeout(typingTimeout);

        // Set a new timeout
        setTypingTimeout(setTimeout(async () => {
            if (name === "handphone" && value.length >= 11) {
                setLoad(true);
                const phoneValidationData = JSON.stringify({
                    "api_key": "ZVSUZTUJ1JWFNFZ9",
                    "number_key": "BOLShiBVuKeG8aTd",
                    "phone_no": value
                });

                const phoneValidationConfig = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://api.watzap.id/v1/validate_number',
                    headers: { 'Content-Type': 'application/json' },
                    data: phoneValidationData
                };

                const maxRetries = 5; // Batas maksimum pengulangan
                let attempt = 0; // Inisialisasi jumlah percobaan

                try {
                    let response;
                    do {
                        response = await axios.request(phoneValidationConfig);
                        attempt++;
                        console.log(attempt);
                    } while (response.data.status === "1005" && attempt < maxRetries) {
                        console.log(`repeat ${attempt} "${response.data.message}"`);
                        console.log(response.data);

                    }; // Ulangi jika code 1005 dan belum mencapai batas
                    setPhoneStatus(response.data);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoad(false); // Set loading to false after validation
                }
            }
        }, 1500)); // Adjust the delay as needed (1000 ms = 1 second)
    }

    const copyToClipboard = () => {
        if (response?.virtual_account) {
            navigator.clipboard.writeText(response.virtual_account);
            swal("Copied!", "Virtual Account number copied to clipboard.", "success");
        } else {
            swal("Error!", "No Virtual Account number available.", "error");
        }
    };

    const [finish, setFinish] = useState(false);
    const registrasi = async (e) => {
        e.preventDefault();
        setLoad(true);

        if (phoneStatus?.status > 200) {
            alert('Nomor WhatsApps tidak valid');
            setLoad(false);
            return;
        }

        try {
            const res = await axios.post('api/users/aplikan', formInput, {
                withCredentials: false,
                headers: { "Access-Control-Allow-Origin": "*" }
            });

            if (res.data?.error_code > 300) {
                setLoad(false);
                return;
            }
            setResponse(res.data.data);
            setFinish(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoad(false);
        }
    }

    const handleClose = () => {
        // console.log('hui');
        setFinish(false);
    };

    return (
        <>
            <Header />
            {finish && (
                <>
                    <Dialog
                        open={true}
                        // onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="  w-full p-4 flex justify-center items-center">
                            <div className="text-center rounded-lg w-full  p-6 bg-white">
                                <div className="text-center w-full h-full ">
                                    <div className="text-center" >
                                        <div>
                                            <div>
                                                <div className="px-6 py-4" >
                                                    <div className=" leading-5" >
                                                        <div className="p-4 flex text-7xl justify-center text-green-500">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <div className=" text-lg  font-bold" >Berhasil Daftar</div>
                                                        <div className=" text-sm font-light " >Jenjang Pendidikan : {response?.jenjang}</div>
                                                    </div>
                                                    <div className="mt-2 text-md font-light" >
                                                        Silahkan Lakukan Pembayaran Formulir
                                                    </div>
                                                    <div className=" font-bold text-2xl md:py-2 py-2">
                                                        Rp. {response?.jumlahTagihan.toLocaleString()} -,
                                                    </div>
                                                    <div className=" font-bold text-2xl md:py-2 py-2">
                                                        <div className="mx-auto w-1/3">
                                                            <img src={briva} alt="BANK" />
                                                        </div>
                                                        <div className=" flex items-center justify-center ">
                                                            <span id="va">{response?.virtual_account}</span><button id="copy" className=" p-2 text-gray-400 " onClick={copyToClipboard}><FaCopy /></button>
                                                        </div>
                                                    </div>
                                                    <div className="  text-sm font-light" >
                                                        <div>
                                                            Tagihan dikirim ke:
                                                        </div>
                                                        <div className="mt-1 mx-auto">
                                                            <div className=" flex justify-center items-center font-medium"><span className=" px-2"><FaPhoneAlt /></span>{response?.handphone}</div>
                                                            <div className=" flex justify-center items-center  font-medium"><span className=" px-2"><FaEnvelope /></span>{response?.email}</div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" w-full flex pb-4 justify-center">
                                            <div onClick={handleClose} >
                                                <Button variant="outlined" color="error" autoFocus>
                                                    <div className="text-red-500">
                                                        Selesai
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div >
                        </div>

                    </Dialog>
                </>
            )}
            <div>
                <div className=" md:grid md:grid-cols-3 h-screen md:bg-white bg-gray-300">
                    <div className=" md:block hidden  mt-20 w-full min-h-[720] col-span-2">
                        <div className="w-full min-h-[720]">
                            <img src={mhs} alt="lol" className="w-[284px] h-[283px] absolute left-[577.5px] top-[257.5px] object-contain" />
                            <div className="w-[489px] h-[269.44px]">
                                <p className="w-[471.84px] h-[40.96px] absolute left-[100px] top-[292.1px] text-[40px] font-semibold text-left text-black">
                                    <span className="w-full leading-3 text-[40px] font-semibold text-left text-black">
                                        Daftar di Universitas Ekasakti
                                    </span>
                                </p>
                                <p className="w-[489px] h-[151.54px] absolute left-[100px] top-[410px] text-[22px] font-medium text-left text-black">
                                    Bergabung jadi mahasiswa dan mulai pengalaman belajar disini.
                                </p>
                            </div>
                            <div className="w-[324.5px] h-[46.11px]">
                                <p className="w-[324.09px] h-[23.62px] absolute left-[100px] top-[482px] text-xl text-left text-black">
                                    Jika kamu sudah memiliki Akun
                                </p>
                                <p className="w-[324.09px] h-[23.62px] absolute left-[100px] top-[504.49px] text-xl text-left">
                                    <span className="w-[324.09px] h-[23.62px] text-xl text-left text-black">Anda dapat </span>
                                    <span className="w-[324.09px] h-[23.62px] text-xl font-semibold text-left text-[#4d47c3]">
                                        <NavLink to={'/login'}> Login disini !</NavLink>
                                    </span>
                                </p>
                            </div>
                        </div >
                    </div >

                    <div className="right flex justify-center items-center  w-full h-full  ">
                        <div className="flex w-full min-h-screen items-center justify-center">
                            <div className="w-full m-4 bg-white relative py-8 rounded-xl bg-transparent bg-clip-border text-gray-700 md:shadow-none shadow-lg">
                                <div className="px-8 w-full h-auto font-sans text-2xl font-bold   text-blue-gray-900 ">
                                    PENDAFTARAN CALON MAHASISWA BARU
                                </div>
                                <div className=" flex w-full h-full justify-center items-center">
                                    <form onSubmit={registrasi} className="  md:mt-4 mb-2 w-full p-8 md:p-4 sm:w-96">
                                        <div className="mb-4 ">
                                            <div className="w-full mb-4 ">
                                                <label className=" flex items-start text-left text-sm font-light text-gray-700">Nama <sup className=" text-red-700 text-xl p-0 m-0">*</sup></label>
                                                <input value={formInput.nama} name="nama" onChange={formInputHeandler} type="text" className="bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Nama Lengkap" required />
                                            </div>
                                            <div className="w-full mb-4 ">
                                                <label className=" flex items-start text-left text-sm font-light text-gray-700">Email <sup className=" text-red-700 text-xl p-0 m-0">*</sup></label>
                                                <input value={formInput.email} name="email" onChange={formInputHeandler} type="email" id="email" className="bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Email" required />
                                            </div>
                                            <div className="  w-full mb-4 ">
                                                <label className=" flex items-start text-left text-sm font-light text-gray-700">No WhatsApps <sup className=" text-red-700 text-xl p-0 m-0">*</sup></label>
                                                <div className=" relative">
                                                    <span className=" md:right-6 right-1 bottom-1 absolute">{load ? (<LoadingSmall />) : ''}</span>
                                                    <input placeholder='08123456789' value={formInput.handphone} name="handphone" onChange={formInputHeandler} type="number" id="handphone" className=" bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                                                </div>
                                                <div className=" flex items-center">
                                                    {phoneStatus && <small className={` px-1  flex ${phoneStatus.status <= 200 ? 'text-green-500 text-sm' : 'text-red-500'}`}> {phoneStatus.message}</small>} {/* Display error message */}
                                                </div>
                                            </div>
                                            <div className="w-full mb-2 ">
                                                <label className=" flex items-start text-left text-sm font-light text-gray-700">Jenjang Pendidikan <sup className=" text-red-700 text-xl p-0 m-0">*</sup></label>
                                                <select name="jenjang" value={formInput.jenjang} onChange={formInputHeandler} className=" bg-gray-50 border border-[#bca0a1]   text-gray-900 text-sm rounded-lg  focus:ring-red-900 h-10 focus:border-red-900 block w-full p-2.5  " placeholder="pilih">
                                                    <option value='' >Jenjang Pendidikan</option>
                                                    <option value="E">D3</option>
                                                    <option value="C">S1</option>
                                                    <option value="B">S2</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button className="mt-6 block w-full select-none rounded-lg bg-[#60282a] py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="submit" data-ripple-light="true">
                                            Daftar
                                        </button>
                                        <p className="mt-4 block text-center font-sans text-base font-normal leading-relaxed text-gray-700 antialiased">
                                            <span> Sudah Punya Akun?</span>
                                            <a className="font-semibold text-pink-500 transition-colors hover:text-blue-700" href="/login" >Login</a>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Backdrop
                sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={load}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Daftar;