import { useState, useEffect } from 'react';
import { ValidationBiodata } from '../../../helper/validation';
import axios from 'axios';
import { isAuth } from "../../../helper/auth";
import { formatDate01 } from '../../../helper/helper';

const PersonalDataForm = ({ onSubmitSuccess, status }) => {
    const user = isAuth()
    const token = JSON.parse(localStorage.getItem("token"))
    const header = { Authorization: `Bearer ${token}` }
    const [formBiodata, setFormBiodata] = useState({
        nik: '',
        nama: '',
        kelamin: '',
        tempatLahir: '',
        tanggalLahir: '',
        agama: '',
        wargaNegara: '',
        kebangsaan: '',
        golonganDarah: '',
        statusPerkawinan: '',
        tinggiBadan: '',
        beratBadan: '',
        alatTransportasi: '',
        ukuranJaket: '',
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        const newData = { ...formBiodata, [name]: value };
        setFormBiodata(newData);
        setErrors(ValidationBiodata(newData));
    }
    const readOnly = status === "N";
    const disable = status === "N";
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = ValidationBiodata(formBiodata);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {

            axios.patch(`api/formulir/dataPribadi`, formBiodata, { headers: header })
                .then((res) => {
                    onSubmitSuccess()
                    console.log("Form data is valid. Submitting form...");
                }).catch((e) => {
                    console.log(e);
                })

        } else {
            console.log("Form data has errors. Not submitting form.");
        }
    }
    useEffect(() => {
        // Logika yang ingin dijalankan setelah komponen dimuat
        getBiodata(); // Contoh memanggil fungsi getBiodata saat komponen dimuat
        // eslint-disable-next-line
    }, []); // Array kosong berarti useEffect ini hanya dijalankan sekali setelah komponen pertama kali dimuat

    const getBiodata = () => {
        axios.get(`api/formulir/dataPribadi`, { headers: header })
            .then((v) => {
                setFormBiodata(
                    {
                        nik: v.data[0].Nik || '',
                        nama: v.data[0].Nama || '',
                        kelamin: v.data[0].Kelamin || '',
                        tempatLahir: v.data[0].TempatLahir || '',
                        tanggalLahir: formatDate01(v.data[0].TanggalLahir) || '',
                        agama: v.data[0].Agama || '',
                        golonganDarah: v.data[0].GolonganDarah || '',
                        statusPerkawinan: v.data[0].StatusSipil || '',
                        wargaNegara: v.data[0].WargaNegara || '',
                        kebangsaan: v.data[0].Kebangsaan || '',
                        tinggiBadan: v.data[0].TinggiBadan || '',
                        beratBadan: v.data[0].BeratBadan || '',
                        alatTransportasi: v.data[0].AlatTransportasi || '',
                        ukuranJaket: v.data[0].UkuranJaket || ''

                    }
                )
            })
    }
    return (
        <form onSubmit={handleSubmit} className="md:mt-4 mb-2 w-full p-0 md:p-4 ">
            <div className=' md:grid md:grid-cols-2'>
                <div className="mb-4 w-full flex flex-col gap-3 px-2">
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">NIK</label>
                        <input value={formBiodata.nik} onChange={(e) => handleChange(e)} name="nik" type="text" placeholder="Masukkan NIK" className="bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className=' text-left flex text-red-500'>{errors.nik && errors.nik}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Nama Lengkap</label>
                        <input value={formBiodata.nama} onChange={(e) => handleChange(e)} name="nama" type="text" placeholder="Masukkan Nama Lengkap" className="bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className=' text-left flex text-red-500'>{errors.nama && errors.nama}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-500">Pilih Jenjang</label>
                        <select value={formBiodata.kelamin} onChange={(e) => handleChange(e)} name="kelamin" className="h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" placeholder="pilih" readOnly={readOnly} disabled={disable}>
                            <option value="">Jenis Kelamin</option>
                            <option value="P">Laki-Laki</option>
                            <option value="W">Perempuan</option>
                        </select>
                        <small className=' text-left flex text-red-500'>{errors.kelamin && errors.kelamin}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Tempat Lahir</label>
                        <input value={formBiodata.tempatLahir} onChange={(e) => handleChange(e)} name="tempatLahir" type="text" placeholder="Masukkan Tempat Lahir" className="bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className=' text-left flex text-red-500'>{errors.tempatLahir && errors.tempatLahir}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Tanggal Lahir</label>
                        <input value={formBiodata.tanggalLahir} onChange={(e) => handleChange(e)} name="tanggalLahir" type="date" placeholder="Pilih Tanggal Lahir" className=" h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className=' text-left flex text-red-500'>{errors.tanggalLahir && errors.tanggalLahir}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-500">Agama</label>
                        <select value={formBiodata.agama} onChange={(e) => handleChange(e)} name="agama" className="h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" placeholder="pilih" readOnly={readOnly} disabled={disable}>
                            <option value="">Pilih Agama</option>
                            <option value="I">Islam</option>
                            <option value="K">Kristen</option>
                            <option value="B">Buddha</option>
                            <option value="H">Hindu</option>
                            <option value="K">Katolik</option>
                            <option value="P">Protestan</option>
                            <option value="Konghucu">Konghucu</option>
                        </select>
                        <small className=' text-left flex text-red-500'>{errors.agama && errors.agama}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Warga Negara</label>
                        <select value={formBiodata.wargaNegara} onChange={(e) => handleChange(e)} name="wargaNegara" className="h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" placeholder="pilih Warna Negara" readOnly={readOnly} disabled={disable}>
                            <option value="">Pilih Warga Negara</option>
                            <option value="WNI">WNI</option>
                            <option value="WNA">WNA</option>
                        </select>
                        <small className=' text-left flex text-red-500'>{errors.wargaNegara && errors.wargaNegara}</small>
                    </div>
                </div>
                <div className="mb-4 w-full flex flex-col gap-3 px-2">
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Kebangsaan</label>
                        <input value={formBiodata.kebangsaan} onChange={(e) => handleChange(e)} name="kebangsaan" type="text" placeholder="Masukkan Kebangsaan" className="bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className=' text-left flex text-red-500'>{errors.kebangsaan && errors.kebangsaan}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Golongan Darah</label>
                        <input value={formBiodata.golonganDarah} onChange={(e) => handleChange(e)} name="golonganDarah" type="text" placeholder="Masukkan Golongan Darah" className="bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className=' text-left flex text-red-500'>{errors.golonganDarah && errors.golonganDarah}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-500">Status Perkawinan</label>
                        <select value={formBiodata.statusPerkawinan} onChange={(e) => handleChange(e)} name="statusPerkawinan" className=" h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" placeholder="pilih" readOnly={readOnly} disabled={disable}>
                            <option value="">Pilih Status Kawin</option>
                            <option value="Menikah">Menikah</option>
                            <option value="Lajang">Lajang</option>
                        </select>
                        <small className=' text-left flex text-red-500'>{errors.statusPerkawinan && errors.statusPerkawinan}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Tinggi Badan</label>
                        <input value={formBiodata.tinggiBadan} onChange={(e) => handleChange(e)} name="tinggiBadan" type="number" placeholder="Masukkan Tinggi Badan" className="bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className=' text-left flex text-red-500'>{errors.tinggiBadan && errors.tinggiBadan}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Berat Badan</label>
                        <input value={formBiodata.beratBadan} onChange={(e) => handleChange(e)} name="beratBadan" type="number" placeholder="Masukkan Berat Badan" className="bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className=' text-left flex text-red-500'>{errors.beratBadan && errors.beratBadan}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Alat Transportasi</label>
                        <input value={formBiodata.alatTransportasi} onChange={(e) => handleChange(e)} name="alatTransportasi" type="text" placeholder="Masukkan Alat Transportasi" className="bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className=' text-left flex text-red-500'>{errors.alatTransportasi && errors.alatTransportasi}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Ukuran Jaket</label>
                        <select value={formBiodata.ukuranJaket} onChange={(e) => handleChange(e)} name="ukuranJaket" className=" h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable}>
                            <option value="">Pilih Ukuran Almamater</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="XL">XL</option>
                            <option value="XXL">XXL</option>
                        </select>
                        <small className=' text-left flex text-red-500'>{errors.ukuranJaket && errors.ukuranJaket}</small>
                    </div>
                </div>
            </div>
            {
                status === 'Y' ? (<button className='bg-[#60282a] hover:bg-red-900 text-white font-bold py-2 px-4 rounded ' type='submit'>Selanjutnya</button>) : ""
            }
        </form>
    );
};

export default PersonalDataForm;