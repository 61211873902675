import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import axios from "axios";
import { Provider } from 'react-redux';
import store from './store.jsx';
if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = 'http://localhost:3001/'
} else {
  axios.defaults.baseURL = 'https://dev-pmb.unespadang.ac.id/'
}

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>

        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);